import React from "react";
import Link from "next/link";
import * as ICONS from "@/icons";
import { useTranslation } from "next-i18next";

const HomeTeamfinder = () => {
  const { t } = useTranslation(["home", "common"]);

  return (
    <div className="teamfinderBg relative mx-auto w-full max-w-7xl py-5">
      <h2 className="relative mb-4 pt-12 text-center text-3xl font-bold uppercase md:text-left">
        <span className="text-primary">Recruit</span> with teamfinder
      </h2>
      <div className="relative grid grid-rows-4 gap-4 pb-12 md:grid-cols-[repeat(2,_20rem)] md:grid-rows-2">
        <div className="rounded-xl bg-card p-6 shadow-xl md:h-72">
          <ICONS.UsersIcon className="h-9 w-9 rounded-md bg-primary p-2 text-gray-200" />
          <p className="mt-4 text-2xl font-semibold text-gray-300">
            1<span className=" text-primary">.</span>{" "}
            {t("register", { ns: "common" })}!
          </p>
          <p className="mt-4">{t("teamfinderRegisterDescription")}</p>
        </div>
        <div className="rounded-xl bg-card__light p-6 shadow-xl md:h-72">
          <ICONS.CommunitiesIcon className="h-9 w-9 rounded-md bg-primary p-2 text-gray-200" />
          <p className="mt-4 text-2xl font-semibold text-gray-300">
            2<span className=" text-primary">.</span>{" "}
            {t("find", { ns: "common" })}!
          </p>
          <p className="mt-4">{t("teamfinderFindDescription")}</p>
        </div>
        <div className="rounded-xl bg-card p-6 shadow-xl md:h-72 md:bg-card__light">
          <ICONS.GamepadIcon className="h-9 w-9 rounded-md bg-primary p-2 text-gray-200" />

          <p className="mt-4 text-2xl font-semibold text-gray-300">
            3<span className=" text-primary">.</span>{" "}
            {t("teamfinderParticipateHeading")}!
          </p>
          <p className="mt-4">{t("teamfinderParticipateDescription")}</p>
        </div>
        <div className="flex items-center justify-center rounded-xl bg-card__light pl-10 shadow-xl md:h-72 md:bg-card">
          <p className="text-4xl font-semibold leading-snug text-gray-300">
            {t("to", { ns: "common" })}{" "}
            <Link href={"/teamfinder"}>
              <a className="group inline-flex items-center text-blue-400 hover:underline">
                Teamfinder{" "}
                <span className="ml-2">
                  <ICONS.ArrowRightIcon />
                </span>
              </a>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeTeamfinder;

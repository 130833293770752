import React from "react";
import Image from "next/image";
import { useTranslation } from "next-i18next";

const HomeCommunitiesHighlight = () => {
  const { t } = useTranslation(["home", "common"]);

  return (
    <div className="flex h-full flex-col bg-card">
      <div className="relative h-full">
        <Image
          src="/images/Community_highlight.webp"
          layout="fill"
          className="object-cover"
          alt=""
        />
      </div>
      <div className="p-6">
        <p>
          <span className="font-medium text-slate-300">LeagueConnect</span>{" "}
          {t("joinCommunityDescription")}
        </p>
        <button className="mt-6 w-fit rounded-md bg-primary px-3 py-2 text-sm font-semibold tracking-wide text-slate-300">
          <a href="/discord"> {t("joinDiscord", { ns: "common" })}</a>
        </button>
      </div>
    </div>
  );
};

export default HomeCommunitiesHighlight;

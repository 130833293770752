import React from "react";
import Link from "next/link";
import { COMMUNITIES_OVERVIEW } from "@/constants/routes";

interface Props {
  filterValue: "ORGANISATION" | "COMMUNITY" | "ASSOCIATION";
  title: string;
  description: string;
  image: string;
}

const HomeCommunityLink = (props: Props) => {
  const { filterValue, title, description, image } = props;

  return (
    <Link
      href={{ pathname: COMMUNITIES_OVERVIEW, query: { type: filterValue } }}
      passHref
    >
      <a className="flex h-40 items-center justify-center rounded-lg">
        <div className="group relative h-full w-full">
          <div
            style={{
              backgroundImage: `url(${image})`,
            }}
            className="absolute inset-0 h-full w-full bg-cover object-cover opacity-50 transition-all ease-in-out group-hover:opacity-30"
          ></div>
          <div className=" absolute inset-0 flex h-full w-full flex-col items-center justify-center p-4 hover:text-primary md:items-end md:justify-start">
            <h3
              className="text-3xl font-bold"
              style={{ textShadow: "1px 1px rgba(0, 0, 0, 0.6)" }}
            >
              {title}
            </h3>
            <p
              className="hidden -translate-y-full text-slate-300 opacity-0 duration-300 group-hover:translate-y-0 group-hover:opacity-100 md:block"
              style={{ textShadow: "1px 1px rgba(0, 0, 0, 0.6)" }}
            >
              {description}
            </p>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default HomeCommunityLink;

import React from "react";
import HomeCommunitiesHighlight from "./HomeCommunitiesHighlight";
import HomeCommunityLink from "./HomeCommunityLink";
import { useTranslation } from "next-i18next";

const HomeCommunities = () => {
  const { t } = useTranslation(["home", "common"]);

  return (
    <div>
      <h2 className="mb-4 pt-12 text-center text-3xl font-bold uppercase md:text-left">
        <span className="text-primary">Join</span> a community
      </h2>
      <div className="grid gap-4 md:grid-cols-12 md:grid-rows-3">
        <div className="md:col-span-4 md:row-span-3">
          <HomeCommunitiesHighlight />
        </div>
        <div className="md:col-span-8">
          <HomeCommunityLink
            title={t("organisations", { ns: "common" })}
            description={t("organisationsImageAlt")}
            filterValue="ORGANISATION"
            image="/images/Community_organization.webp"
          />
        </div>
        <div className="md:col-span-8">
          <HomeCommunityLink
            title={t("communities", { ns: "common" })}
            description={t("communitiesImageAlt")}
            filterValue="COMMUNITY"
            image="/images/Community_communities.webp"
          />
        </div>
        <div className="md:col-span-8">
          <HomeCommunityLink
            title={t("associations", { ns: "common" })}
            description={t("associationsImageAlt")}
            filterValue="ASSOCIATION"
            image="/images/Community_associations.webp"
          />
        </div>
      </div>
    </div>
  );
};

export default HomeCommunities;

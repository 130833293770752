import Link from "next/link";
import React from "react";
import * as ROUTES from "@/constants/routes";
import { formatRank } from "@/utils";
import { Competition } from "@/models/competition.model";
import { useTranslation } from "next-i18next";

interface Props {
  competition: Competition;
}

const CompetitionCard = (props: Props) => {
  const { competition } = props;
  const { t } = useTranslation(["competition", "common"]);

  return (
    <Link href={`${ROUTES.COMPETITION}${competition.friendlyUrl}`}>
      <a className="block">
        <div className="group h-full overflow-hidden bg-card shadow duration-75 ease-in hover:bg-card__light sm:rounded-lg">
          <div className="p-4 sm:px-6">
            <h3 className="text-xl font-semibold leading-6 text-gray-300">
              {competition.name}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-400">
              {t("start")} {competition.formattedTime.startDate}
            </p>
          </div>
          <div className="border-t border-slate-700/40 px-4 group-hover:border-slate-700/60 sm:p-0">
            <dl className="group-hover:divide-slate-700/60 sm:divide-y sm:divide-slate-700/40">
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-300">
                  {t("game")}
                </dt>
                <dd className="mt-1 text-sm text-gray-400 sm:col-span-2 sm:mt-0">
                  {competition.game}
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-300">
                  {t("teams")}
                </dt>
                <dd className="mt-1 text-sm text-gray-400 sm:col-span-2 sm:mt-0">
                  {competition.numParticipants}
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-300">
                  {t("location")}
                </dt>
                <dd className="mt-1 text-sm text-gray-400 sm:col-span-2 sm:mt-0">
                  {competition.location}
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-300">
                  {t("prize")}
                </dt>
                <dd className="mt-1 text-sm text-gray-400 sm:col-span-2 sm:mt-0">
                  {competition.prize}
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-300">
                  {t("ranksReq")}
                </dt>
                <dd className="mt-1 text-sm text-gray-400 sm:col-span-2 sm:mt-0">
                  {formatRank(competition.minRank)} -{" "}
                  {formatRank(competition.maxRank)}
                </dd>
              </div>
            </dl>
          </div>
          <div className="border-t border-slate-700/40 p-4 pt-5 pb-5 text-gray-300 group-hover:border-slate-700/60 sm:px-6">
            <small>
              <span className="font-semibold ">{t("organisedBy")} </span>
              {competition.organisers}
            </small>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default CompetitionCard;

import Head from "next/head";
import * as Home from "@/components-page/home";
import { UserLayout } from "@/components/Layout";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import type { NextPageWithLayout } from "./_app";

const HomePage: NextPageWithLayout = () => {
  const { t } = useTranslation("pageTitle");

  return (
    <>
      <Head>
        <title>{`${t("homePage")} ${t("baseTitle")}`}</title>
      </Head>
      <Home.HomeIntro />
      <Home.HomeTeamfinder />
      <Home.HomeCommunities />
      <Home.HomeCompetitions />
    </>
  );
};

export async function getStaticProps(props: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(props.locale, [
        "home",
        "common",
        "competition",
        "pageTitle",
      ])),
    },
  };
}

HomePage.getLayout = UserLayout;

export default HomePage;

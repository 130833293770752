import React, { useEffect, useState } from "react";
import useSWR from "swr";
import CompetitionCard from "../competition-overview/CompetitionCard";
import { Competition } from "@/models/competition.model";

const HomeCompetitions = () => {
  const [competitions, setCompetitions] = useState([] as Competition[]);

  const { data } = useSWR(`/api/competitions`);

  useEffect(() => {
    if (!data) return;

    setCompetitions(data);
  }, [data]);

  return (
    <div className="mt-16">
      <h2 className="relative mb-4 pt-12 text-center text-3xl font-bold uppercase md:text-left">
        <span className="text-primary">Compete</span> against other players
      </h2>
      <div className="grid gap-4 md:grid-cols-2 xl:grid-cols-3">
        {competitions.map((competition, index) => (
          <CompetitionCard key={index} competition={competition} />
        ))}
      </div>
    </div>
  );
};

export default HomeCompetitions;

import { useTranslation } from "next-i18next";

const HomeIntro = () => {
  const { t } = useTranslation("home");

  return (
    <div className="w-full max-w-7xl pt-16 pb-20 text-center lg:w-3/5 lg:py-32 lg:text-left xl:pr-16">
      <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
        {t("title")}
      </h1>
      <p className="mx-auto mt-3 max-w-md text-lg font-semibold uppercase text-blue-500 sm:text-xl md:mt-5 md:max-w-3xl">
        {t("undertitle")}
      </p>
      <p className="mt-4 text-lg font-medium text-slate-400">
        {t("description")}
      </p>
    </div>
  );
};

export default HomeIntro;
